<template>
  <div class="content content3 flex-column" style="padding: 10px">
    <div class="nameTit flex-center-v">
      <span>用户管理</span>
      <el-button type="primary" size="mini" plain style="margin-left: 20px" @click="addUser">新增用户</el-button>
      <el-button type="primary" size="mini" plain style="margin-left: 20px" @click="amendUser" >修改用户</el-button>
      <el-button type="primary" size="mini" plain style="margin-left: 20px" @click="resetPwd" >重置密码</el-button>
      <el-button v-if="false" type="danger" size="mini" plain style="margin-left: 20px" @click="deleteUser" >删除用户</el-button>
    </div>
    <div class="cont flex-1-auto">
      <el-table ref="multipleTable" :data="userPageList" height="100%" style="width: 100%" @selection-change="selectItem" tooltip-effect="light myTooltips">
        <el-table-column type="selection" width="50"></el-table-column>
        <el-table-column prop="nickname" label="姓名" width="250"></el-table-column>
        <el-table-column prop="username" label="手机号码" width="250"></el-table-column>
        <el-table-column label="状态" align="center" width="250">
          <template slot-scope="{ row }">
            <span :style="{color: row.userStatus?'#67c23a':'red'}">{{ row.userStatus ? '启用中' : '禁用中' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="remarks" label="备注" show-overflow-tooltip></el-table-column>
        <el-table-column label="操作" align="center" width="100">
          <template slot-scope="{ row }">
            <el-button v-if="!row.userStatus" type="text" size="mini" @click="enableOrDisable(row, 'star')">启用</el-button>
            <el-button v-else type="text" size="mini" @click="enableOrDisable(row, 'end')">禁用</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="flex-center" style="padding-top: 10px">
      <el-pagination
          v-if="userListTotal > 10"
          background
          :current-page.sync="searchUserValue.pageCurrent"
          :page-size="searchUserValue.pageSize"
          :total="userListTotal"
          layout="total, prev, pager, next"
          @current-change="handleSizeChangeData"
      ></el-pagination>
    </div>
    <!-- 新增用户弹框 -->
    <el-dialog title="新增用户" v-if="isShowAddUser" :visible.sync="isShowAddUser" width="30%">
      <el-form :model="addUserForm" class="demo-form-inline" label-width="80px">
        <el-form-item label="姓名">
          <el-input v-model="addUserForm.nickname" placeholder="请输入姓名"></el-input>
        </el-form-item>
        <el-form-item label="手机号">
          <el-input v-model="addUserForm.username" placeholder="请输入手机号"></el-input>
        </el-form-item>
        <el-form-item label="备注">
          <el-input type="textarea" v-model="addUserForm.remarks"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="confirmUser" size="small">确 定</el-button>
        <el-button @click="isShowAddUser = false" size="small">取 消</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Apis from './apis2.js'
export default {

  name: 'user',

  components: {
  },

  props: {
  },

  data() {
    return {
      userPageList: [],
      multipleSelection: [],
      searchUserValue: {
        departmentCode: '',
        pageCurrent: 1,
        pageSize: 100
      },
      userListTotal: 0,
      // 3
      isShowAddUser: false,
      addUserForm: {
        nickname: '',
        username: '',
        remarks: ''
      }
    }
  },

  computed: {
    ...mapGetters(['companyId'])
  },

  watch: {
    companyId() {
      this.getUserPageList()
    }
  },

  mounted() {
    if(this.companyId) this.getUserPageList()
  },

  methods: {
    async getUserPageList() {
      this.searchUserValue.departmentCode = this.companyId
      const data = await Apis.findUserList(this.searchUserValue)
      // console.log(data)
      this.userPageList = data.list
      this.userListTotal = data.total
    },
    // 启用禁用
    enableOrDisable(data, status) {
      const title = status == 'star' ? '是否启用该用户' : '是否作禁用用户'
      this.$confirm(title, '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        if (status == 'star') await Apis.enableUser(data.id)
        else await Apis.disableUser(data.id)
        this.$message.success('操作成功')
        // this.init().async
        this.getUserPageList().async
      })
    },
    // 切换页码
    async handleSizeChangeData(val) {
      // console.warn(val)
      this.searchUserValue.pageCurrent = val
      this.getUserPageList().async
    },
    // 选中
    selectItem(rows) {
      if (rows.length > 1) {
        var newRows = rows.filter((it, index) => {
          if (index == rows.length - 1) {
            this.$refs.multipleTable.toggleRowSelection(it, true);
            return true;
          } else {
            this.$refs.multipleTable.toggleRowSelection(it, false);
            return false;
          }
        })
        this.multipleSelection = newRows;
      } else {
        this.multipleSelection = rows;
      }
      // console.log(this.multipleSelection)
    },
    // 新增用户
    addUser() {
      delete this.searchUserValue.id
      this.addUserForm.departmentCode = this.companyId
      this.addUserForm.nickname = ''
      this.addUserForm.username = ''
      this.addUserForm.remarks = ''
      this.isShowAddUser = true
      this.isUserEdit = false
    },
    // 新增用户确定
    async confirmUser() {
      if (this.isUserEdit) {
        await Apis.updateUser(this.addUserForm)
        this.$message.success('操作成功')
      } else {
        await Apis.addUser(this.addUserForm)
        this.$message.success('操作成功')
        this.searchUserValue.pageCurrent = 1
      }
      this.getUserPageList().async
      this.isShowAddUser = false
    },
    // 修改用户
    amendUser() {
      if (this.multipleSelection.length == 0) return this.$message.warning('请选择一个用户')
      const data = this.multipleSelection[0]
      if (data.username == 'admin') return
      this.isUserEdit = true
      this.addUserForm = JSON.parse(JSON.stringify(data))
      this.isShowAddUser = true
    },
    // 重置密码
    resetPwd() {
      if (this.multipleSelection.length == 0) return this.$message.warning('请选择一个用户')
      const data = this.multipleSelection[0]
      this.$confirm('是否重置密码', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await Apis.resetPwd(data.id)
        this.$message.success('操作成功')
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    },
    // 删除用户
    deleteUser() {
      if (this.multipleSelection.length == 0) return this.$message.warning('请选择一个用户')
      const data = this.multipleSelection[0]
      if (data.username == 'admin') return
      this.$confirm('是否删除该用户', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        await Apis.delUser(data.id)
        this.$message.success('操作成功')
        this.getUserPageList().async
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '已取消'
        })
      })
    }
  }
}

</script>

<style lang="scss" scoped>

</style>
